@use '../abstracts/animations' as *;
@use '../abstracts/variables' as *;

.loader-wrapper {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  div {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 64px;
    height: 64px;
    border: 3px solid $primary-light-color;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary-light-color transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &--small {
    width: 32px;
    height: 32px;

    div {
      top: 4px;
      left: 4px;
      width: 24px;
      height: 24px;
      border-width: 3px;
      // margin: 2px;
    }
  }

  &--primary {

    div {
      border-color: $primary-color transparent transparent transparent;
    }
  }

  &--normal {
    border: 3px solid $quaternary-bg-color; 
    border-top: 3px solid $primary-color; 
    border-radius: 50%;
    animation: spin 1s linear infinite;

    div {
      display: none;
    }
  }

  &--normal-white {
    @extend .loader--normal;
    border-top-color: $primary-light-color;
  }
}