@use '../abstracts/variables' as *;

.alert {
  padding: 20px 20px 20px 52px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  margin-bottom: 20px;
  font-family: $ff-primary-medium;
  font-size: 0.875rem;

  &__icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;

    svg,
    img {
      display: block;
    }
  }

  &--error {
    background-color: $error-bg-color;
    color: $error-text-color;
    border-color: $error-border-color;
    
    .alert__icon {
      color: $error-icon-color;
    }
  }
  &--warning {
    background-color: $warning-bg-color;
    color: $warning-text-color;
    border-color: $warning-border-color;
    
    .alert__icon {
      color: $warning-icon-color;
    }
  }
  &--success {
    background-color: $success-bg-color;
    color: $success-text-color;
    border-color: $success-border-color;
    
    .alert__icon {
      color: $success-icon-color;
    }
  }
  &--info {
    background-color: $info-bg-color;
    color: $info-text-color;
    border-color: $info-border-color;
    
    .alert__icon {
      color: $info-icon-color;
    }
  }
}