@use '../abstracts/variables' as *;

body {
  font-size: 16px;
  color: $primary-text-color;
  font-family: $ff-primary;
}

p {
  font-size: 1rem;
  line-height: 1.375;
}

h1 {
  font-family: $ff-primary-bold;
  font-size: 2.5rem;
  line-height: 1.375;
  color: $secondary-color;
}

h2 {
  font-family: $ff-primary-bold;
  font-size: 1.625rem;
  color: $secondary-text-color;
}

h3 {
  font-family: $ff-primary-demi;
  font-size: 1.25rem;
  line-height: 1.2;
  color: $secondary-color;
}

h4 {
  font-family: $ff-primary-demi;
  font-size: 1.125rem;
}

h5 {
  font-family: $ff-primary-medium;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1.375;
}

h6 {
  font-family: $ff-primary-medium;
  font-size: 1rem;
  line-height: 1.375;
  margin-bottom: 1rem;
}