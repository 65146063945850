@use '../abstracts/variables' as *;

.btn {
  display: inline-flex;
  height: 40px;
  align-items: center;
  border: 1px solid $primary-border-color;
  outline: 0;
  font-size: 0.875rem;
  padding: 0 14px;
  font-family: $ff-primary-medium;
  border-radius: 4px;
  background-color: $primary-light-color;
  color: $primary-text-color;
  cursor: pointer;
  transition: border-color .3s ease, background-color .3s ease;

  &:hover {
    border-color: $primary-dark-color;
    background-color: rgba($primary-color, 0.3);
  }

  &:focus,
  &:active {
    border-color: $primary-dark-color;
    background-color: rgba($primary-color, 0.3);
    border-width: 2px;
  }

  &:disabled {
    background-color: $primary-bg-color;
    color: $quinary-text-color;

    &:hover {
      background-color: $primary-bg-color;
      color: $quinary-text-color;
      border-color: inherit;
      cursor: not-allowed;
    }
  }

  &--light-secondary {
    color: $secondary-color;
    border: 0;
    transition: background-color .3s ease;

    &:hover {
      background-color: $primary-bg-color;
    }
  }

  &--secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: $primary-light-color;
    transition: background-color .3s ease, border-color .3s ease;

    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }

  &--error {
    background-color: $error-border-color;
    border-color: $error-border-color;
    color: $primary-light-color;
    transition: background-color .3s ease, border-color .3s ease;

    &:hover {
      background-color: darken($error-border-color, 5);
      border-color: darken($error-border-color, 5);
    }
  }

  &--min {
    min-width: 110px;
  }

  &--centered {
    justify-content: center;

    .btn__text {
      flex: 0 1 auto;
    }
  }

  &--outline-primary {
    background-color: transparent;
    border-color: $primary-color;
    color: $primary-color;
    transition: color .3s ease, background-color .3s ease;

    &:hover {
      background-color: $primary-color;
      color: $primary-light-color;
      border-color: $primary-color;
    }

    &:active,
    &:focus {
      background-color: transparent;
      color: $primary-color;
      border-color: $primary-color;
    }
  }

  &--outline-secondary {
    background-color: transparent;
    border-color: $secondary-color;
    color: $secondary-color;
    transition: background-color .3s ease, border-color .3s ease;

    &:hover {
      background-color: $primary-light-color;
      border-color: $primary-light-color;
    }
  }

  &--transparent {
    border-color: $primary-light-color;
    background-color: rgba($primary-light-color, 0.2);
    color: $primary-light-color;
    transition: all .3s ease;

    &:hover {
      background-color: rgba($primary-light-color, 0.4);
      border-color: $primary-light-color;
    }

    &:focus {
      border-color: $primary-light-color;
    }
  }

  &--sm {
    padding: 0 12px;
    height: 28px;
    font-size: 0.8125rem;
    font-family: $ff-primary-demi;
  }

  &--mini {
    height: 18px;
    font-size: 0.75rem;
    padding: 0 6px;
    border: 1px solid $primary-color;
    color: $primary-color;
    transition: all .3s ease;

    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $primary-light-color;
    }
  }

  &--normal,
  &--normal-primary {
    padding: 0 !important;
    color: $secondary-color;
    border: 0;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  &--normal-primary {
    color: $primary-color;
    font-family: $ff-primary-medium;
  }

  &--icon-primary {
    .btn__icon {
      color: $primary-color;
    }
  }

  &--loading {
    cursor: default;

    &.btn--primary {

      &:hover {
        background-color: $primary-color;
      }
    }
  }

  &--icon-rotate {

    .btn__icon {
      transform: rotate(-45deg);
      transition: transform .6s ease;
    }

    &:hover {

      .btn__icon {
        transform: rotate(-405deg);
      }
    }
  }

  &--icon-animate {
    padding-left: 44px;
    position: relative;

    .btn__icon {
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);
      backface-visibility: hidden;
    }

    &:hover {

      .btn__icon {
        animation: arrowFadeMove2 .6s ease-in-out forwards;
      }
    }
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  &__icon {
    display: block;
    font-size: 1.5rem;
    margin-right: 6px;

    svg {
      display: block;
    }
  }

  &--md {
    height: 45px;
  }

  &--lg {
    height: 53px;
  }

  &--primary {
    background-color: $primary-color;
    color: $primary-light-color;
    border: 0;
    font-family: $ff-primary-bold;
    font-size: 0.9375rem;
    transition: background-color .3s ease;

    &:hover {
      background-color: $secondary-color;
    }
  }

  &--fullwidth {
    width: 100%;
  }

  &--text-small {
    font-size: 0.75rem;
  }
  
  &--bold {
    font-family: $ff-primary-bold;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.icon-small {

    .btn__icon {
      font-size: 1.125rem;
    }
  }

  &.hide-text-xs {

    @media (max-width: $break-xs-max) {
      .btn__text {
        display: none;
      }
    }
  }
}

.btn-arrow {
  display: block;
  align-items: center;
  width: 370px;
  height: 80px;
  padding: 0px 26px;
  text-align: center;
  position: relative;
  font-family: $ff-primary-demi;
  font-size: 1rem;
  border-radius: 4px;
  background-color: $primary-light-color;
  cursor: pointer;
  border: 1px solid $primary-border-color;
  outline: 0;
  transition: border .3s ease;

  @media (max-width: $break-xl-max) {
    padding-right: 16px;
    padding-left: 16px;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 26px;
    transform: translateY(-50%);
    display: block;
    max-height: 45px;
    max-width: 90px;

    @media (max-width: $break-xl-max) {
      max-width: 50px;
      left: 16px;
    }

    @media (max-width: $break-lg-max) {
      max-width: 40px;
    }

    svg,
    img {
      width: 38px;
      max-width: 100%;
      max-height: inherit;
      display: block;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 26px;
    transform: translateY(-50%);
    color: $primary-border-color;
    display: block;
    opacity: 1;
    transition: all .3s ease;

    @media (max-width: $break-xl-max) {
      right: 16px;
    }

    svg {
      font-size: 2rem;
      display: block;
    }
  }

  &__text {
    display: block;
    padding: 0 40px;
  }

  &__subtitle {
    font-size: 9px;
    color: gray;
  }

  &:hover {
    border-color: $primary-dark-color;
    background-color: rgba($primary-color, 0.3);
    transition: border-color .3s ease, background-color .3s ease;

    .btn-arrow__arrow {
      color: $primary-dark-color;
      animation: arrowFadeMove .6s ease-in-out forwards;
    }
  }

  &:focus,
  &:active {
    border-color: $primary-dark-color;
    background-color: rgba($primary-color, 0.3);
    border-width: 2px;
    transition: bolder .3s ease, background-color .3s ease;

    .btn-arrow__arrow {
      color: $primary-dark-color;
      animation: arrowFadeMove .6s ease-in-out forwards;
    }
  }

  &--icon {
    .btn-arrow__text {
      padding: 0 80px;

      @media (max-width: $break-xl-max) {
        padding: 0 60px;
      }
    }
  }

  &--full-break-sm-max {

    @media (max-width: $break-sm-max) {
      width: 100%;
    }
  }
}