@use '../abstracts/variables' as *;

.intro {
  padding: 50px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: $break-md-max) {
    display: block;
  }

  &.with-buttons {
    padding: 40px 0 0;
    margin-bottom: 40px;

    &--2 {
      @media (max-width: $break-lg-max) {

        .intro__right {
          margin-top: 20px;
        }
      }

    }
  }

  &__left {
    margin-right: auto;
  }

  &__right {
    margin-left: auto;
    display: flex;
    align-items: center;
  
    @media (max-width: $break-md-max) {
      margin-top: 20px;
    }

    button {
      margin-left: 15px;
    }
  }

  &__subtitle {
    font-size: 1.125rem;
  }
}