@use '../abstracts/variables' as *;

.help {

  &__head {
    margin-bottom: 28px;

    h4 {
      color: $secondary-color;
    }
  }
}