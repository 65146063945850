@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;

.radio {

  &__choices {
    display: flex;
    align-items: center;

    > div {
      position: relative;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
  
      input {
        display: none;
      }
  
      input:checked ~ label div {
        border-color: $primary-color;
  
        &::after {
          opacity: 1; 
        }
      }

      &:hover {
        
        .radio__choices_btn::after {
          opacity: 1;
        }
      }
    }

    &_label {
      display: flex;
      cursor: pointer;

      p {
        font-size: 1rem;
        font-family: $ff-primary-medium;
      }
    }

    &_btn {
      width: 20px;
      height: 20px;
      border: 2px solid $primary-color;
      border-radius: 10px;
      position: relative;
      margin-right: 6px;

      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: $primary-color;
        border-radius: 6px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity .3s ease;
      }
    }
  }

  &__error {
    @include formError;
  }
}