@use './variables' as *;

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
};

@mixin formField {
  background-color: $primary-light-color;
  padding: 8px 14px 9px;
  border-radius: 4px;
  border: 2px solid transparent;
  outline: 0;
  font-family: $ff-primary-medium;
  font-size: 0.875rem;
  color: $primary-dark-color;
  width: 100%;
  transition: border-color .3s ease;

  @include placeholder {
    color: $input-icon-color;
  }

  &:focus {
    border-color: $secondary-color;
  }
};

@mixin formFieldDashboard {
  background-color: $primary-bg-color;
  border-radius: 0;
  color: $secondary-color;
};

@mixin formLabelDashboard {
  font-size: 1rem;
  font-family: $ff-primary-bold;
  color: $primary-color;
  margin-bottom: 8px;
  display: block;
};

@mixin formError {
  padding-top: 5px;
  font-size: 0.75rem;
  font-family: $ff-primary-demi;
  color: $error-text-color;
};

@mixin centerElement {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}