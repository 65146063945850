@media print {
  .main-layout {
    padding-top: 0 !important;
  }

  .header {
    border: 0 !important;
    position: relative !important;
    background-color: transparent !important;

    &__nav {
      display: none !important;  
    }
  }

  .intro {
    padding-top: 0 !important;
    margin-top: -82px !important;

    &__left {
      text-align: right !important;
    }

    &__right {
      display: none !important;
    }
  }

  .solution-detail {

    &__top {

      .btn {
        display: none !important;
      }
    }
  }

  .solution-images {

    &__main {

      .zoom-image__container,
      a {
        display: none !important;
      }
    }

    &__thumbs {
      display: none !important;
    }
  }

  .solution-info {

    &__icons,
    &__actions,
    &__documentation {
      display: none !important;
    }

    &__desc {
      display: block !important;
      position: relative !important;
      overflow: visible !important;

      // .desc-wrapper {
      //   display: inline !important;
      //   // break-inside: avoid !important;
      //   // page-break-inside: avoid !important;
      //   // width: 100% !important;
      // }
      
      // p {
      //   position: relative !important;
      //   break-inside: always !important;
      //   overflow: visible !important;
      //   // margin-bottom: 0 !important;
      //   display: inline-block !important;
      //   width: 100%;
      //   position: relative;

      //   strong, span {
      //     break-before: avoid !important;
      //     break-after: avoid !important;
      //     break-inside: avoid !important;
      //   }
      // }
    }

    &__disclaimer {
      page-break-inside: avoid !important;
    }
  }
}