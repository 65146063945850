@use '../abstracts/variables' as *;

.account-read {

  &__section {
    margin-bottom: 70px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &__group {
    padding-bottom: 19px;
    display: flex;
    margin-bottom: 24px;
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: calc(100% + 56px);
      height: 1px;
      background-color: rgba($quaternary-border-color, 0.16);
    }

    &_action {
      color: $secondary-color;
      display: block;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 15px;
      cursor: pointer;
      transition: background-color .3s ease;
      
      &:hover {
        background-color: $primary-bg-color;
      }
      
      svg {
        display: block;
        font-size: 1.5rem;
      }
    }

    @media(max-width: $break-sm-max) {
      display: block;

      &::after {
        width: calc(100% + 20px);
      }
    }
  }

  &__box {
    width: calc(50% - 20px);
    margin-right: 40px;

    @media(max-width: $break-sm-max) {
      margin-bottom: 29px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &_label {
      font-family: $ff-primary-demi;
      font-size: 0.75rem;
      color: $primary-color;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    &_field {
      font-family: $ff-primary-medium;
      font-size: 0.875rem;
      color: $secondary-color;
    }
  }

  .password-change {

    &__label {
      font-family: $ff-primary-demi;
      font-size: 0.75rem;
      color: $primary-color;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
  }
}