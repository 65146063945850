@use '../abstracts/variables' as *;

.form {

  &__section {
    margin-bottom: 70px;
  }

  &__group {
    display: flex;

    .form-input,
    .select {
      width: calc(50% - 5px);

      &:first-child {
        margin-right: 10px;
      }

      &--dashboard {
        width: calc(50% - 20px);

        &:first-child {
          margin-right: 40px;
        }
      }
    }

    @media (max-width: $break-sm-max) {
      &--break-sm {
        display: block;
  
        .form-input,
        .select {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    @media (max-width: $break-xxs-max) {
      &--break-xxs {
        display: block;
  
        .form-input,
        .select {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }
  }

  &__foot {
    display: flex;
    padding-top: 7px;

    .btn--normal-primary {
      margin-right: auto;
      margin-left: 31px;

      @media (max-width: $break-xxs-max) {
        margin-left: 0;
      }
    }

    .btn--primary {
      min-width: 186px;
    }

    &--2 {
      justify-content: center;

      .btn--normal-primary {
        margin-right: 34px;
      }

      .btn--primary {
        min-width: 110px;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;

    .btn {
      margin: 0 8px;

      &--outline-secondary {
        transition: color .3s ease, border-color .3s ease;

        &:hover {
          border-color: $primary-color;
          color: $primary-color;
        }
      }
    }
  }
}