@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.form-textarea {
  margin-bottom: 29px;

  &__field {
    @include formField;
    height: 192px;
    min-width: 100%;
    max-width: 100%;
    resize: none;

    &.error {
      border-color: $error-border-color;
    }
  } 

  &__error {
    @include formError;
  }

  &--dashboard {

    .form-textarea__label {
      @include formLabelDashboard;
      display: block;
    }
  
    .form-textarea__field {
      @include formFieldDashboard;
    }
  }

  &--dashboard-light {

    .form-textarea__label {
      @include formLabelDashboard;
      display: block;
    }
  
    .form-textarea__field {
      @include formFieldDashboard;
      background-color: $primary-light-color;
    }
  }
}