@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.default-checkbox {
  margin-bottom: 9px;

  label {
    display: flex;
  }

  input {
    display: none;

    &:checked ~ .default-checkbox__button {
      background-color: $primary-color;

      svg {
        opacity: 1;
      }
    }
  }

  &__button {
    width: 18px;
    height: 18px;
    border-radius: 3px;
    background-color: $primary-light-color;
    color: $primary-light-color;
    border: 1px solid $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 9px;
    margin-top: 3px;

    svg {
      display: block;
      opacity: 0;
    }
  }

  &__text {
    color: $tertiary-text-color;
    font-size: 0.75rem;

    a {
      color: $secondary-color;
      text-decoration: none;
    }
  }

  &__error {
    @include formError;
  }
}