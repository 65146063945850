@use '../abstracts/variables' as *;

.auth {

  &__box {
    padding: 32px;
    background-color: rgba($primary-color, 0.3);
    border-radius: 6px;
  
    p {
  
      a {
        color: $primary-text-color;
        font-size: 0.6875rem;
      }
    }

    &_main {

      form {

        .btn {
          margin-top: 18px;
        }
      }
    }
  }

  &__note {
    color: $tertiary-text-color;
    padding-top: 15px;
    text-align: center;

    &_account {
      padding-top: 10px;
      font-family: $ff-primary-medium;
      font-size: 0.875rem;

      a {
        color: $secondary-color;
        margin-left: 12px;
      }
    }

    &_terms {
      font-size: 0.75rem;
      margin-bottom: 6px;
    }
  }
}