@use '../abstracts/variables' as *;

.authentication {
  padding: 130px 15px;

  @media (max-width: $break-lg-max) {
    padding: 80px 15px;
  }
  @media (max-width: $break-md-max) {
    padding: 40px 15px;
  }
  @media (max-width: $break-sm-max) {
    padding: 15px;
  }

  &__close {
    position: absolute;
    top: 44px;
    right: 44px;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color .3s ease;
    
    &:hover {
      background-color: $primary-bg-color;
    }

    @media (max-width: $break-sm-max) {
      top: 10px;
      right: 10px;
    }
  }

  &__inner {
    max-width: 385px;
    margin: 0 auto;
  }

  &__header {

    img {
      display: block;
    }
  }

  .intro {
    padding: 15px 0 30px;
    min-width: 290px;

    .container {
      padding: 0;
      max-width: none;
      min-width: 290px;
    }

    h1 {
      font-size: 2rem;
      margin-bottom: 2px;
    }

    &__subtitle {
      font-size: 0.875rem;
    }
  }
}