.filters {
  margin-bottom: 50px;

  &__inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .select {
      margin-bottom: 12px;
    }
  }
}