@use '../abstracts/variables' as *;
@use '../abstracts//mixins.scss' as *;

.solution-images {
 
  &__main {
    margin-bottom: 40px;
    position: relative;

    &--default {
      width: 500px;
      height: 375px;
      border: 1px solid #8080804d;
      img {
        @include centerElement;
      }

      @media (max-width: $break-xl-max) {
        width: 400px;
        height: 300px;
      }
      @media (max-width: $break-lg-max) {
        width: 100%;
        width: 500px;
        height: 100%;
        height: 375px;
      }
      @media (max-width: $break-md-max) {
        width: 100%;
        min-height: 200px;
        height: auto;
      }
    }
    
    img {
      display: block;
    }

    .loader {
      @include centerElement;
    }
  }

  &__thumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &_thumb {
      margin-right: 40px;
      margin-bottom: 40px;
      width: 100px;
      height: 100px;
      cursor: pointer;
      transition: box-shadow .3s ease;

      &:hover {
        box-shadow: 0 0 5px rgba($shadow-color, 0.5);
      }

      &:last-child {
        margin-right: 0;
      }

      img {
        display: block;
      }
    }
  }
}