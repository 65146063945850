@use '../abstracts/variables' as *;

.project-list {
  background-color: $primary-light-color;
  box-shadow: 0 2px 4px 0 rgba($secondary-bg-color, 0.3);
  padding-right: 31px;
  border-radius: 5px;
  margin-bottom: 13px;
  transition: box-shadow .3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__inner {
    position: relative;
    padding: 15px 0 15px 33px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }

  @media (max-width: $break-md-max) {
    margin-left: 30px;
  }

  &:hover {
    box-shadow: 0 6px 10px 0 rgba($secondary-bg-color, 0.6);
    cursor: pointer;

    .checkbox-wrapper {
      opacity: 1;
    }
  }

  &.active {
    border: 4px solid $success-color;
    box-shadow: 0 2px 10px 0 rgba($secondary-bg-color, 0.9);

    .checkbox-wrapper {
      opacity: 1;
    }
  }

  .checkbox-wrapper {
    position: absolute;
    right: 100%;
    top: 0;
    height: 100%;
    opacity: 0;
    transition: opacity .3s ease;
    padding-right: 13px;
    display: flex;
    align-items: center;

    @media (max-width: $break-md-max) {
      opacity: 1;
    }
  }

  &__icon {
    font-size: 3.125rem;
    color: $primary-color;
    width: 74px;

    @media (max-width: $break-sm-max) {
      margin-bottom: 20px;
    }

    svg {
      display: block;
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 50px;
    }

    &_mp {
      position: relative;
      top: -5px;

      img:nth-child(2) {
        position: absolute;
        top: 5px;
        left: 5px;
      }
      img:nth-child(3) {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }
  }

  &__title {
    width: 303px;

    @media (max-width: $break-sm-max) {
      width: 100%;
    }

    @media (min-width: $break-xl-min) {
      width: 35%;
    }

    h4 {
      color: $secondary-color;
    }
  }

  &__solutions {
    width: 425px;
    padding: 15px 0;

    @media (max-width: $break-sm-max) {
      width: 100%;
    }
    @media (min-width: $break-xl-min) {
      width: 55%;
      padding-left: 15px;
    }

    p {
      font-family: $ff-primary-medium;
      font-size: 0.75rem;
      display: flex;
      align-items: center;

      span {
        font-family: $ff-primary-bold;
        background-color: $primary-color;
        color: $primary-light-color;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        border-radius: 6px;
        font-size: 0.6875rem;
        margin-right: 6px;
        height: 16px;
      }
    }
  }

  &__more {
    // position: absolute;
    // right: 31px;
    // top: 50%;
    // transform: translateY(-50%);
    position: relative;

    &_btn {
      color: $primary-border-color;
      font-size: 1.375rem;
      cursor: pointer;

      svg {
        display: block;
      }
    }

    &_dropdown {
      position: absolute;
      top: calc(100% + 4px);
      left: 50%;
      transform: translateX(-50%);
      background-color: $primary-light-color;
      width: 116px;
      padding: 16px 8px 16px 11px;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba($primary-dark-color, 0.2);
      z-index: 2;

      &::before {
        width: 100%;
        height: 10px;
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 3px;
        content: '';
        background-color: $primary-light-color;
      }

      &::after {
        position: absolute;
        top: -5px;
        margin-left: -3px;
        left: 50%;
        content: '';
        background-color: $primary-light-color;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        box-shadow: inherit;
      }

      li {
        font-size: 0.8125rem;
        font-family: $ff-primary-demi;
        color: $secondary-color;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 4px;
        transition: background-color .3s ease;

        &:hover {
          background-color: $primary-bg-color;
        }

        &:last-child {
          margin-bottom: 0;
        }

        svg {
          font-size: 1rem;
          margin-right: 6px;
        }
      }

      @media(max-width: $break-md-max) {
        left: auto;
        right: 0;
        transform: translateY(0);

        &::after {
          left: auto;
          right: 6px;
        }
      }
    }
  }
}