@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.file-upload {

  &__label {
    @include formLabelDashboard;
  }

  &__field {
    display: none;
  }

  &__group {
    display: flex;
    align-items: center;

    @media (max-width: $break-sm-max) {
      display: block;
    }
  }

  &__btn {
    width: 150px;
    height: 100px;
    border: 1px solid $primary-color;
    background-color: rgba($primary-color, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    margin-right: 15px;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      border-color: $secondary-color;
      background-color: rgba($secondary-color, 0.3);
    }

    svg {
      display: block;
      font-size: 3.3125rem;
      color: $primary-light-color;
    }

    @media (max-width: $break-sm-max) {
      margin-bottom: 15px;
    }
  }

  &__error {
    @include formError;
  }
}