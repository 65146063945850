// Colors
$primary-color: #0BA1E2;
$secondary-color: #222469;

$primary-dark-color: #000;
$primary-light-color: #fff;

$primary-border-color: #C9C9C9;
$secondary-border-color: #e9e9e9;
$tertiary-border-color: #424494;
$quaternary-border-color: #939393;
$quinary-border-color: #e2e2e2;

$primary-text-color: #000;
$secondary-text-color: #787879;
$tertiary-text-color: #5D606B;
$quaternary-text-color: #9BA4B0;
$quinary-text-color: #cfcfcf;

$primary-bg-color: #F1F1F2;
$secondary-bg-color: #00a0e5;
$tertiary-bg-color: #0C1E40;
$quaternary-bg-color: #CAEBFD;

$shadow-color: #063E57;
$shadow-color-2: #727272;

$modal-bg: #898989;

$input-icon-color: #bfc4cc;

$error-bg-color: #FDECEB;
$error-border-color: #F77A72;
$error-text-color: #C0362D;
$error-icon-color: #C0362D;
$warning-bg-color: #FFF4E5;
$warning-border-color: #DCAD6C;
$warning-text-color: #B57726;
$warning-icon-color: #FFA229;
$info-bg-color: #E9F4FD;
$info-border-color: #389FF4;
$info-text-color: #2A75B2;
$info-icon-color: #389FF4;
$success-bg-color: #EDF7EE;
$success-border-color: #5DB661;
$success-text-color: #457C46;
$success-icon-color: #65BB6A;

$success-color: #4EC48A;

// Fonts
$ff-primary: 'Avenir Next';
$ff-primary-demi: 'Avenir Next Demi';
$ff-primary-bold: 'Avenir Next Bold';
$ff-primary-medium: 'Avenir Next Medium';

$ff-secondary: 'Nunito Sans', sans-serif;

// Breakpoints
$break-xxs-max: 375px;
$break-xxs-min: 376px;
$break-xs-max: 480px;
$break-xs-min: 481px;
$break-sm-max: 575px;
$break-sm-min: 576px;
$break-md-max: 767px;
$break-md-min: 768px;
$break-lg-max: 991px;
$break-lg-min: 992px;
$break-xl-max: 1199px;
$break-xl-min: 1200px;