@use '../abstracts/variables' as *;
@use '../abstracts/animations' as *;

.project-grid {
  border-radius: 3px;
  background-color: $primary-light-color;
  box-shadow: 0 2px 4px 0 rgba($shadow-color, 0.3);
  overflow: hidden;
  transition: box-shadow .3s ease;

  &.active {
    border: 4px solid $success-color;

    &:hover {

      .project-grid__icon {
  
        &_hover {
  
          .btn {
            opacity: 1;
          }
  
          .more {
            opacity: 1;
          }
        }
      }
    }

    .project-grid__icon {
      height: calc(100% - 62px);

      &_hover {
        opacity: 1;

        .btn {
          top: 50%;
          opacity: 0;
        }

        .checkbox {
          top: 16px;
        }

        .more {
          top: 16px;
          opacity: 0;
        }
      }
    }

    .project-grid__content {
      height: 62px;
      padding-top: 10px;
    }
  }

  &__inner {
    padding-top: 100%;
    position: relative;

    section {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 14px 26px;
    }
  }
  
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  &--new {
    background-color: rgba($primary-color, 0.3);
    border: 1px solid $primary-color;

    .project-grid__icon {
      color: $primary-light-color;
    }
  }

  &__placeholder {
    position: absolute;
    top: -14px;
    left: -26px;
    width: calc(100% + 52px);
    height: calc(100% + 28px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $primary-light-color;
    border-radius: 3px;
    cursor: default;
    z-index: 10;

    &_icon {
      height: calc(100% - 90px);
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        width: 120px;
        height: 110px;
        background-color: $primary-bg-color;
        position: relative;
        overflow: hidden;

        // &::after {
        //   content: '';
        //   width: 80%;
        //   height: 100%;
        //   position: absolute;
        //   top: 0;
        //   left: -80%;
        //   background-color: $primary-border-color;
        //   animation: placeholderMoveImg 1s ease-in-out infinite;
        // }
      }
    }

    &_text {
      height: 90px;

      div {
        background-color: $primary-bg-color;
        width: 216px;
        position: relative;
        overflow: hidden;

        &::after {
          content: '';
          width: 80%;
          height: 100%;
          position: absolute;
          top: 0;
          left: -100%;
          background-color: $primary-border-color;
          animation: placeholderMoveRight 1s ease-in-out infinite;
        }
        
        &:nth-child(1) {
          height: 25px;
          margin-bottom: 8px;
        }
        
        &:nth-child(2) {
          height: 16px;
          
          &::after {
            left: -120%;
            animation: placeholderMoveRight2 1s ease-in-out infinite;
          }
        }
      }
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4.25rem;
    color: $primary-color;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 90px);
    transition: height .3s ease;

    img {
      display: block;
      max-width: 100%;
      max-height: 110px;
    }

    &_mp {
      position: relative;
      top: -10px;

      img:nth-child(2) {
        position: absolute;
        left: 10px;
        top: 10px;
      }
      img:nth-child(3) {
        position: absolute;
        left: 20px;
        top: 20px;
      }
    }

    &_hover {
      position: absolute;
      top: -14px;
      left: -26px;
      background-color: rgba($tertiary-bg-color, 0.9);
      width: calc(100% + 52px);
      height: calc(100% + 14px);
      border-radius: 3px 3px 0 0;
      opacity: 0;
      transition: opacity .3s ease;

      .btn {
        min-width: 110px;
        position: absolute;
        left: 50%;
        top: calc(50% - 50px);
        transform: translate(-50%, -50%);
        transition: all .3s ease;
      }

      .checkbox {
        position: absolute;
        top: -50px;
        left: 16px;
        transition: top .3s ease;
      }

      .more {
        position: absolute;
        top: -50px;
        right: 16px;
        font-size: 1.25rem;
        color: $primary-light-color;
        background-color: transparent;
        width: 27px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all .3s ease;

        &.active, &:hover {
          background-color: $secondary-text-color;
        }

        svg {
          display: block;
        }

        &__dropdown {
          position: absolute;
          top: calc(100% + 9px);
          right: 0;
          background-color: $primary-light-color;
          width: 116px;
          padding: 16px 8px 16px 11px;
          border-radius: 3px;
          z-index: 10;

          &::after {
            bottom: 100%;
            right: 6px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba($primary-light-color, 0);
            border-bottom-color: $primary-light-color;
            border-width: 6px;
            margin-left: -6px;
          }

          li {
            font-size: 0.8125rem;
            font-family: $ff-primary-demi;
            color: $secondary-color;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            transition: background-color .3s ease;
            padding: 4px;
            border-radius: 2px;

            &:hover {
              background-color: $primary-bg-color;
            }

            &:last-child {
              margin-bottom: 0;
            }

            svg {
              font-size: 1rem;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }

  &__content {
    height: 90px;
    text-align: center;
    color: $secondary-color;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: height .3s ease, padding .3s ease;

    h4 {
      margin-bottom: 8px;
    }

    p {
      font-family: $ff-primary-medium;
      font-size: 0.75rem;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-family: $ff-primary-bold;
        background-color: $primary-color;
        color: $primary-light-color;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        border-radius: 6px;
        font-size: 0.6875rem;
        margin-right: 6px;
        height: 16px;
      }
    }
  }

  &:hover:not(.project-grid--new) {

    .project-grid__icon {
      height: calc(100% - 62px);

      &_hover {
        opacity: 1;

        .btn {
          top: 50%;
        }

        .checkbox {
          top: 16px;
        }

        .more {
          top: 16px;
        }
      }
    }

    .project-grid__content {
      height: 62px;
      padding-top: 10px;
    }
  }

  &:hover {
    box-shadow: 0 5px 10px 0 rgba($shadow-color, 0.62);
    cursor: pointer;
  }

  &--mp {

    .project-grid__icon {
      height: calc(100% - 70px);
    }

    .project-grid__content {
      height: 60px;

      h4 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 1rem;
        margin-bottom: 0;

        span {
          margin-left: 5px;
          background-color: $primary-color;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          font-size: 12px;
          color: $primary-light-color;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}