@use '../abstracts/variables' as *;

.projects {
  padding-bottom: 100px;

  &--grid {
    overflow: hidden;
    width: calc(100% + 20px);
    margin-left: -10px;
    padding: 0 10px;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    color: $secondary-color;

    &_right {
      display: flex;
      align-items: center;
      font-size: 1.5rem;

      a {
        margin-left: 12px;
        width: 32px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color .3s ease;
        border-radius: 3px;
        color: $secondary-color;

        &.active {
          color: $primary-color;
        }

        &:hover {
          background-color: rgba($primary-color, 0.3);
        }
      }
    }
  }

  &__body {

    &--grid {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% + 51px);

      .project-box {
        width: 270px;
        position: relative;
        margin-right: 51px;
        margin-bottom: 51px;
      }

      // @media (max-width: $break-lg-max) {
      //   width: calc(100% + 20px);

      //   .project-box {
      //     margin-right: 20px;
      //     margin-bottom: 20px;
      //   }
      // }
    }

    &--list {
      overflow: visible;
    }

    &_top {
      margin-bottom: 30px;

      ul {
        display: flex;
        align-items: center;

        li {
          padding-right: 18px;
          margin-right: 18px;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 19px;
            background-color: $primary-color;
            transform: translateY(-50%);
          }

          &:last-child {
            
            &::after {
              display: none;
            }
          }

          a {
            font-size: 0.8125rem;
            font-family: $ff-primary-demi;
            color: $secondary-color;
            display: flex;
            align-items: center;
            text-decoration: none;
            padding: 5px 8px;
            border-radius: 3px;
            transition: background-color .3s ease, color .3s ease;

            i {
              display: block;
              font-size: 1.5rem;
              margin-right: 7px;
            }

            &:hover {
              background-color: $primary-color;
              color: $primary-light-color;

              i::before {
                color: $primary-light-color;
              }
            }
          }
        }
      }
    }
  }
}