@use '../abstracts/variables' as *;

.steps-wrapper {
  position: relative;

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
  }

  &.loading {

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 11;
    }
  }
}

.step {
  // overflow: hidden;

  &__title {

  }

  &__buttons{
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: calc(100% + 30px);

    @media (max-width: $break-sm-max) {
      width: 100%;
    }

    > div {
      margin-right: 30px;
      margin-bottom: 30px;
      width: 368px;

      .btn-arrow {
        width: 100%;
      }

      // @media (max-width: $break-md-max) {
      //   width: calc(100%/2 - 15px);

      //   &:nth-child(3n+3) {
      //     margin-right: 30px;
      //   }
      //   &:nth-child(2n+2) {
      //     margin-right: 0;
      //   }
      // }

      @media (max-width: $break-sm-max) {
        width: 100%;
        margin-right: 0;

        &:nth-child(3n+3) {
          margin-right: 0;
        }
      }
    }
  }

  &__no-solutions {

    p {
      font-size: 1rem;
      font-family: $ff-primary-bold;
      color: $secondary-text-color;
      margin-bottom: 28px;
    }

    .btn {
      max-width: 260px;
      padding: 10px 43px;
      height: auto;
    }
  }
}