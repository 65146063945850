@use '../abstracts/variables' as *;

.accordion {
  box-shadow: 0 2px 4px 0 rgba(6, 62, 87, 0.36);
  margin-bottom: 15px;
  border-radius: 5px;

  &.opened {

    .accordion__head {
      border-radius: 5px 5px 0 0;

      span {
        transform: rotate(90deg);
        transform-origin: center center;
      }
    }
  }

  &.disable-click {

    .accordion__head {
      cursor: default;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        content: '';
      }
    }
  }

  &__head {
    background-color: $primary-light-color;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    
    h6 {
      padding: 21px 50px 21px 22px;
      color: $secondary-color;
      margin-bottom: 0;
      z-index: 1;
      position: relative;
    }

    span {
      position: absolute;
      top: 50%;
      right: 17px;
      margin-top: -12px;
      cursor: pointer;
      font-size: 1.5rem;
      transition: transform .3s ease;

      svg {
        display: block;
      }
    }
  }

  &__body {
    background-color: $secondary-color;
    border-radius: 0 0 5px 5px;
    display: none;
    overflow: hidden;
    transition: height .3s ease;

    &_inner {
      padding: 33px 22px;
      color: $primary-light-color;
      font-family: $ff-primary-medium;

      p {
        margin-bottom: 22px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}