@use '../abstracts/variables' as *;

.country {
  margin-bottom: 47px;

  @media (max-width: $break-sm-max) {
    margin-bottom: 22px;
  }
  
  &__inner {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 8px;
    transition: background-color .3s ease;

    &:hover {
      background-color: rgba($primary-color, 0.1);
    }
  }

  &__flag {
    margin-right: 10px;
    border-radius: 50%;
    width: 31px;
    min-width: 31px;
    height: 31px;
    position: relative;
    overflow: hidden;

    img {
      display: block;
      border-radius: inherit;
      position: absolute;
      top: 0;
      left: -3%;
      width: 106%;
      height: 100%;
      max-width: none;
      opacity: 0;
      animation: fadeInFromNone .3s ease-in forwards;
    }
  }

  &__name {
    font-family: $ff-primary-medium;
    color: $primary-text-color;
    transition: color .3s ease;
  }

  &--small {
    margin-bottom: 0;

    .country__inner {
      width: 100%;
      padding: 5px;
      border-radius: 3px;
    }

    .country__flag {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }

    .country__name {
      font-size: 0.875rem;
    }
  }
}