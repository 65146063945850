@use '../abstracts/variables' as *;

.solution-info {
  &__title {
    margin-bottom: 20px;
  }

  &__desc {
    margin-bottom: 20px;

    .desc-wrapper {
      display: table !important;
      break-inside: avoid !important;
      page-break-inside: avoid !important;
      width: 100% !important;
    }
    
    p {
      font-size: 0.875rem;
      margin-bottom: 3px;
      display: flex;
      border: 1px solid rgba($primary-color, 0.3);
      border-radius: 3px;
      line-height: 1.857;
      width: 100%;

      strong {
        font-family: $ff-primary-medium;
        font-weight: normal;
        width: 232px;
        padding: 2px 15px 3px;
        display: block;
        color: $primary-color;
        word-break: normal;

        @media (max-width: $break-sm-max) {
          width: 40%;
        }
      }

      span {
        flex: 1;
        padding: 2px 18px 3px;
        display: block;
        font-family: $ff-primary-medium;
        background-color: rgba($primary-color, 0.3);
        color: $secondary-color;
        word-break: normal;
      
        @media (max-width: $break-sm-max) {
          width: 60%;
        }
      }
    }
  }

  &__icons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: $break-sm-max) {
      display: block;
      margin-bottom: 40px;
    }

    &_box {
      max-height: 60px;
      width: 30px;
      &:first-child {
        width: 25px;
      }
      &:last-child,
      &:nth-child(4) {
        width: 35px;
      }
      div {
        background-color: $primary-light-color;
      }

      img {
        display: block;
        max-height: inherit;
        max-width: none;
        opacity: 0;
        animation: fadeInFromNone 0.3s ease-in forwards;
        width: 40px;
        height: 30px;
      }

      @media (max-width: $break-sm-max) {
        width: 100% !important;
        
        div {
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 20px;

          &::after {
            content: attr(data-tip);
            font-size: 14px;
          }
        }

        svg {
          max-width: 40px;
          width: 100%;
          margin-right: 15px;
        }
        
      }
    }
  }

  &__actions {
    margin-bottom: 42px;
    display: flex;
    justify-content: space-between;

    @media (max-width: $break-xs-max) {
      display: block;

      button {
        width: 100%;

        &:first-child {
          margin-bottom: 10px;
          margin-right: 0;
        }
      }
    }

    button {
      margin-right: 30px;
      flex: 1;

      .btn__icon {
        font-size: 1.125rem;

        img {
          display: block;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__documentation {
    margin-bottom: 42px;
    margin-top: -20px;

    .btn {
      width: calc(50% - 15px);

      &--loading {

        .btn__icon {
          display: none;
        }
      }

      svg {
        fill: $primary-color;
        transition: fill .3s ease;
      }

      &:hover {

        svg {
          fill: $primary-light-color;
        }
      }

      &:active,
      &:focus {

        svg {
          fill: $primary-color;
        }
      }
    }

    @media (max-width: $break-xs-max) {
      margin-top: -32px;

      .btn {
        width: 100%;
      }
    }
  }

  &__mp-btn {
    margin-bottom: 42px;
    margin-top: -22px;
  }

  &__disclaimer {
    color: $secondary-text-color;

    &_title {
      font-size: 0.5625rem;
      font-family: Helvetica;
      font-weight: bold;
      margin-bottom: 4px;
    }

    &_text {
      font-size: 0.5625rem;
      font-family: Helvetica;
    }
  }
}
