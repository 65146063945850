@use '../abstracts/variables' as *;

.no-projects {
  text-align: center;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  h2 {
    font-family: $ff-primary-demi;
    color: $secondary-color;
    font-size: 1.375rem;
    margin-bottom: 49px;
  }

  &__btns {
    max-width: 217px;
    margin: 0 auto;
  }
}