@use '../abstracts/variables' as *;
@use '../abstracts/animations' as *;

.progress {
  margin-bottom: 40px;
  
  &__main {
    overflow: hidden;
    position: relative;

    &_inner {
      display: flex;
      position: relative;
      white-space: nowrap;
      justify-content: flex-end;
      align-content: flex-end;      
    }

    &::after {
      width: 400%;
      height: 5px;
      background-color: $primary-bg-color;
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
    }
  }

  &__top {
    margin-bottom: 35px;

    button {
      margin-left: 15px;
    }
  }

  &__step {
    width: inherit;
    padding-right: 31px;
    display: inline-block;

    &:last-child {
      margin-right: auto;
    }

    p {
      font-size: 0.9375rem;
      line-height: 1.34;
      color: $primary-border-color;
      padding-bottom: 11px;
      position: relative;
      transition: color .3s ease;

      &::after {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 70px;
        height: 5px;
        background-color: transparent;
        content: '';
        transition: background-color .3s ease;
        animation: progressStepWidth .3s ease forwards;
      }
    }

    &.active {

      p {
        color: $primary-text-color;

        &::after {
          background-color: $primary-color;
        }
      }
    }

    &:not(.active):not(.next):hover {
      cursor: pointer;

      p {
        color: $secondary-text-color;

        &::after {
          background-color: $primary-border-color;
        }
      }
    }
  }
}