@use '../abstracts/variables' as *;

.text-section {
  padding: 50px 0;
  margin-top: 60px;

  &__inner {

    section {
      margin-bottom: 50px;
    }

    h1 {
      margin-bottom: 30px;
    }

    h2 {
      margin-bottom: 25px;
      color: $secondary-color;
    }

    h3 {
      margin-bottom: 20px;
    }
  
    p {
      margin-bottom: 20px;

      &.bold {
        font-family: $ff-primary-bold;
      }
    }
  
    h4 {
      margin-bottom: 20px;
    }

    ul {
      list-style-type: disc;
      margin-bottom: 20px;
      margin-left: 20px;

      li {
        margin-bottom: 10px;

        ul {
          margin-top: 10px;
          list-style-type: circle;
        }
      }
    }

    address {
      font-style: normal;
      margin-bottom: 20px;

      p {
        margin-bottom: 5px;
      }
    }

    a {
      color: $primary-color;
    }
  }

}