@use '../abstracts/variables' as *;

.footer {
  text-align: center;
  padding: 22px 15px;

  p {
    font-size: 0.6875rem;
    color: $secondary-text-color;

    a {
      text-decoration: none;
      color: $secondary-text-color;

      &:hover {
        color: $primary-color;
      }
    }
  }
}