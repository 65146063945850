@use '../abstracts/variables' as *;

.solutions {
  width: calc(100% + 30px);
  padding: 0 15px;
  margin-left: -15px;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 30px);

    &--list {
      display: block;
      width: 100%;

      @media (max-width: $break-lg-max) {
        order: 2;
      }
    }

    .solution {
      max-width: 270px;
      margin-right: 30px;

      &--list {
        max-width: 570px;
        margin-right: 0;
      }
    }

    @media (max-width: $break-md-max) {
      width: 100%;

      .solution {
        max-width: 375px;
        width: 100%;
        margin-right: 0;
      }
    }
  }
}