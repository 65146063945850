@use '../abstracts/variables' as *;

.zoom-image {
  position: relative;
  overflow: hidden;
  height: 100%;
  display: inline-block;
  width: 100%;
  img {
    display: block;
    max-width: 100%;
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 200%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    opacity: 0;
    transition: opacity .3s ease;
    z-index: 1;
  }

  &:hover {
    cursor: crosshair;

    .zoom-image__container {
      opacity: 1;
    }
  }

  a {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 2;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    color: $primary-light-color;
    text-decoration: none;
    opacity: 0.2;
    transition: opacity .3s ease;
    background-color: $primary-color;
    padding: 5px;
    border-radius: 3px;

    svg {
      display: block;
      margin-right: 5px;
      font-size: 1.5rem;
    }

    &:hover {
      opacity: 1;
    }
  }
}