@use '../abstracts/variables' as *;

.header {
  padding: 20px 0;
  border-bottom: 1px solid $secondary-border-color;
  min-width: 320px;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $primary-light-color;
  z-index: 50;

  &__brand {

    img {
      display: block;
    }

    @media (max-width: $break-xxs-max) {
      max-width: 100px;
    }
  }

  &__nav {

    ul {
      align-items: center;

      li {
        font-size: 1.25rem;
        margin-left: 22px;
        position: relative;
        padding: 10px 0;

        @media (max-width: $break-md-max) {
          margin-left: 12px;
        }
        @media (max-width: $break-xxs-max) {
          margin-left: 6px;
        }

        &.user-li {
          
          .user {
            margin-left: 0;
          }
        }

        &.multiple-penetration {
          padding: 0;
          margin-right: 20px;
          margin-left: 20px;

          .btn {

            &:disabled {
              color: $primary-border-color;
            }
            
            @media (max-width: $break-md-max) {
              padding: 0 5px;
            }
          }

          span.btn__icon {
            display: none;
            margin-right: 0;

            @media (max-width: $break-md-max) {
              display: block;
            }
          }

          span.btn__text {

            @media (max-width: $break-md-max) {
              display: none;
            }
          }
          
          span.badge {
            position: absolute;
            top: -15px;
            right: -15px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $primary-color;
            color: $primary-light-color;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
          }
        }

        .language-selector-trigger__selected {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }

          span {
            display: block;
            margin-left: 5px;
            text-transform: uppercase;
            font-family: $ff-primary-medium;
            font-size: 0.875rem;
            line-height: 20px;
            text-decoration: underline;
          }
        }

        a {
          color: $primary-border-color;
          transition: color .3s ease;

          svg {
            display: block;
          }

          &:hover {
            color: $primary-color;
          }

          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
        }

        &.opened {
          
          a {
            color: $primary-color;
          }
        }

        .lang-dropdown {
          position: absolute;
          top: calc(100% + 6px);
          left: 0;
          width: 185px;
          border: 2px solid $quinary-border-color;
          background-color: $primary-light-color;
          border-radius: 4px;
          transform: translateX(calc(-50% + 10px));
          z-index: 10;
          padding: 10px 11px 10px 15px;
          max-height: 82vh;
          overflow: scroll;

          &::after, &::before {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }
          
          &::after {
            border-color: rgba($primary-light-color, 0);
            border-bottom-color: $primary-light-color;
            border-width: 8px;
            margin-left: -8px;
          }
          &::before {
            border-color: rgba($quinary-border-color, 0);
            border-bottom-color: $quinary-border-color;
            border-width: 11px;
            margin-left: -11px;
          }

          li {
            font-size: 0.875rem;
            margin-left: 0;
            cursor: pointer;
            padding: 0;
            margin-bottom: 2px;

            &:last-child {
              margin-bottom: 0;
            }

            &:hover,
            &.active {
              
              .country__inner{
                background-color: $primary-bg-color;
              }
            }
          }
        }
      }

      &.user__dropdown {

        li {
          margin-left: 0;
        }
      }
    }
  }
}