@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.select {
  margin-bottom: 15px;

  &.error {

    .select__main {

      &_selected {
        border-color: $error-border-color !important;

        &:hover {
          border-color: $error-border-color;
        }
      }
    }
  }

  &__label {
    display: block;
  }

  &__main {
    position: relative;

    &_selected {
      font-size: 0.875rem;
      font-family: $ff-primary-medium;
      border: 1px solid $primary-border-color;
      padding: 0 50px 0 16px;
      height: 40px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      position: relative;
      transition: border .3s ease;
      cursor: pointer;

      &:hover {
        border-color: $primary-dark-color;
        background-color: rgba($primary-color, 0.3);
        transition: bolder-color .3s ease, background-color .3s ease;
      }

      &.opened {
        border-color: $primary-dark-color;
        background-color: rgba($primary-color, 0.3);
        transition: bolder-color .3s ease, background-color .3s ease;
        border-radius: 4px 4px 0 0;
      }

      &::after {
        top: 50%;
        right: 10px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top-color: #000;
        border-width: 6px;
        margin-left: -6px;
        margin-top: -2px;
      }
    }

    &_dropdown {
      border: 1px solid $primary-border-color;
      background-color: $primary-light-color;
      position: absolute;
      top: 100%;
      left: 0;
      min-width: 100%;
      z-index: 100;
      max-height: 195px;
      overflow-y: auto;

      li {
        padding: 8px 16px;
        border-bottom: 1px solid $primary-border-color;
        cursor: pointer;
        white-space: nowrap;
        transition: background-color .3s ease;

        &:hover {
          background-color: rgba($primary-color, 0.3);
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  &__error {
    @include formError;
  }

  &--dashboard {
    margin-bottom: 29px;

    .select__label {
      @include formLabelDashboard;
    }

    .select__main {
      &_selected {
        border-color: transparent;
        border-radius: 0;
        background-color: $primary-bg-color;
        color: $secondary-color;
  
        &:hover {
          border-color: transparent;
        }
  
        &.opened {
          border-color: $secondary-color;
          border-radius: 0;
        }
      }
  
      &_dropdown {
        border: 1px solid $primary-border-color;
        background-color: $primary-light-color;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 100;
  
        li {
          padding: 8px 16px;
          border-bottom: 1px solid $primary-border-color;
          cursor: pointer;
          transition: background-color .3s ease;
  
          &:hover {
            background-color: $primary-border-color;
          }
  
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}