@use '../abstracts/variables.scss' as *;

.cookies {
  padding: 25px 0 19px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $primary-light-color;
  box-shadow: 0 0 10px rgba($primary-dark-color, 0.2);
  z-index: 100;

  &__title {
    margin-bottom: 22px;
  }

  &__text {
    margin-bottom: 25px;
    font-size: 0.875rem;
  }

  &__check {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    
    div {
      margin-right: 25px;
      display: flex;
      align-items: center;

      svg {
        display: block;
        color: $primary-color;
        font-size: 1.25rem;
      }

      span {
        display: block;
        margin-left: 5px;
      }
    }
  }

  &__actions {

    .btn {
      margin-right: 20px;
      min-width: 156px;
      margin-bottom: 6px;
    }

    .text-link {
      margin-left: 6px;
    }

    @media (max-width: $break-sm-max) {
      .btn {
        min-width: auto;
      }
    }
  }
}