@use '../abstracts/variables' as *;

@font-face {
  font-family: 'Promat-Custom';
  src:
    url('../../fonts/Promat-Custom.ttf?yru7p') format('truetype'),
    url('../../fonts/Promat-Custom.woff?yru7p') format('woff'),
    url('../../fonts/Promat-Custom.svg?yru7p#Promat-Custom') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="custom-icon-"], [class*=" custom-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Promat-Custom' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: block;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before {
    transition: color .3s ease;
  }
}

.custom-icon-dynamic-feed:before {
  content: "\e904";
}
.custom-icon-contact-page:before {
  content: "\e903";
}
.custom-icon-close:before {
  content: "\e902";
  color: $primary-color;
}
.custom-icon-print-outline:before {
  content: "\e90c";
  color: $primary-light-color;
}
.custom-icon-selector .path1:before {
  content: "\e90e";
  color: $primary-color;
}
.custom-icon-selector .path2:before {
  content: "\e90f";
  margin-left: -1em;
  color: $secondary-color;
}
.custom-icon-success:before {
  content: "\e910";
  color: $success-icon-color;
}
.custom-icon-warning:before {
  content: "\e911";
  color: $warning-icon-color;
}
.custom-icon-folder-outline:before {
  content: "\e900";
  color: $secondary-color;
}
.custom-icon-document-outline:before {
  content: "\e901";
  color: $secondary-color;
}
