@use '../abstracts/variables' as *;

.notification {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  min-width: 280px;

  a {
    color: $primary-color;
  }
}