@use '../abstracts/variables' as *;

.popup {
  position: absolute;
  bottom: calc(100% + 9px);
  left: 50%;
  transform: translateX(-50%);
  width: 253px;
  background-color: $primary-light-color;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(131,130,130, 0.5);
  z-index: 150;

  @media (max-width: $break-md-max) {
    left: auto;
    transform: translateY(0);
    right: 0;
  }

  &::after {
    top: 100%;
    left: calc(50% - 3px);
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba($primary-light-color, 0);
    border-top-color: $primary-light-color;
    border-width: 6px;
    margin-left: -6px;

    @media (max-width: $break-md-max) {
      left: auto;
      right: 26px;
    }
  }

  &__head {
    padding: 11px 18px;
    border-bottom: 2px solid $primary-bg-color;

    .main,
    .back {
      display: flex;
      align-items: center;
    }

    .back {
      cursor: pointer;

      svg {
        transition: background-color .3s ease;

        &:hover {
          background-color: $primary-bg-color;
        }
      }
    }

    svg {
      display: block;
      margin-right: 14px;
    }

    h5 {
      font-size: 0.8125rem;
      font-family: $ff-primary-demi;
      margin-bottom: 0;
      padding-top: 4px;
    }
  }

  &__body {
    height: 152px;
    overflow-y: auto;
    position: relative;

    ul {
      padding-top: 1px;

      li {

        .root {
          display: flex;
          align-items: center;
          padding: 7px 18px;
          cursor: pointer;
          transition: background-color .3s ease;

          &:hover {
            background-color: $primary-bg-color;
          }
        }

        svg {
          display: block;

          &.pre-icon {
            color: $primary-color;
            margin-right: 14px;
          }
        }

        p {
          font-family: $ff-primary-demi;
          font-size: 0.8125rem;
          padding-top: 4px;
        }
  
        span {
          margin-left: auto;
          display: block;
          color: $primary-border-color;
        }

        .sublevel {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: $primary-light-color;

          ul {
            position: relative;
          }
        }
      }
    }
  }

  &__foot {
    padding: 11px 11px 11px 18px;
    display: flex;
    align-items: center;
    box-shadow: 0 -1px 4px 0 rgba(195,194,194, 0.5);
    z-index: 1;
    position: relative;

    .new {
      margin-right: auto;
      color: $primary-color;
      cursor: pointer;
      transition: background-color .3s ease;
      padding: 0 4px;
      margin-left: -4px;

      &:hover {
        background-color: $primary-bg-color;
      }

      svg {
        display: block;
      }
    }

    .btn {
      margin-left: 14px;
    }
  }
}