@use '../abstracts/variables' as *;

.language-selector-modal {
  max-width: 1120px;
  padding: 80px;
  background-color: #fff;
  position: relative;
  margin: 80px auto;

  @media (max-width: $break-sm-max) {
    padding: 40px;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: $primary-color;
    font-size: 1.5rem;
    cursor: pointer;
  }

  &__head {
    margin-bottom: 40px;

    h2 {
      font-family: $ff-primary-demi;
      font-size: 2.25rem;
      color: $primary-color;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
    }

    &_selected {
      display: inline-flex;
      background-color: $primary-color;
      color: #fff;
      text-decoration: underline;
      padding: 9px 20px;
      font-size: 1.5rem;
    }
  }
}