@use '../abstracts/variables' as *;

.checkbox {

  input {
    display: none;
  }
  
  &__btn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $primary-light-color;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease;

    svg {
      color: $secondary-color;
      font-size: 1rem;
      opacity: 0;
      transition: opacity .3s ease;
    }
  }

  input:checked ~ .checkbox__btn {
    background-color: $success-color;
    border-color: $success-color;

    svg {
      opacity: 1;
    }
  }

  &--primary {

    .checkbox__btn {
      border: 1px solid $primary-color;
    }
  }
}