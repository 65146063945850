@use '../abstracts/variables' as *;

.page-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  padding: 50px 15px;
  margin: 0 auto;
  height: 100%;

  @media (max-width: $break-md-max) {
    display: block;
    max-width: 400px;

    .page-404__icon {
      max-width: 348px;
      width: 100%;
      margin-bottom: 30px;
    }

    .page-404__text {
      width: 100%;
    }
  }

  &__icon {
    width: 348px;
    margin-right: 49px;

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__text {
    width: 395px;

    h1 {
      font-size: 1.5rem;
      color: $primary-text-color;
      margin-bottom: 24px;
    }
  }
}