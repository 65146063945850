@use '../abstracts/variables' as *;

.multiple-penetration-wrapper {
  display: flex;
  justify-content: space-between;

  @media (max-width: $break-lg-max) {
    flex-direction: column;
  }
}

.multiple-penetration-box {
  max-width: 300px;
  padding: 20px;
  border: 1px solid $primary-border-color;
  align-self: flex-start;

  h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    text-align: center;
    margin-bottom: 20px;

    &.disclaimer {
      margin-top: 20px;
      font-size: 0.85rem;
    }
  }

  @media (max-width: $break-lg-max) {
    order: 1;
    margin-bottom: 50px;
    max-width: 100%;
    width: 100%;
  }
}