@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes progressStepWidth {
  0% {
    width: 0px;
  }
  100% {
    width: 70px;
  }
}

@keyframes arrowFadeMove {
  0% {
    display: none;
    opacity: 0;
    right: 46px;
  }
  1% {
    display: block;
    opacity: 0;
    right: 46px;
  }
  50% {
    opacity: 0.5;
    right: 24px;
  }
  100% {
    opacity: 1;
    right: 26px;
  }
}

@keyframes arrowFadeMove2 {
  0% {
    display: none;
    opacity: 0;
    left: 24px;
  }
  1% {
    display: block;
    opacity: 0;
    left: 24px;
  }
  50% {
    opacity: 0.5;
    left: 12px;
  }
  100% {
    opacity: 1;
    left: 14px;
  }
}

@keyframes moveRight {
  0% {
    left: 0;
  }
  75% {
    width: 15%;
  }
  100% {
    left: 150%;
  }
}

@keyframes moveRight2 {
  0% {
    left: -120%;
  }
  75% {
    width: 15%;
  }
  100% {
    left: 100%;
  }
}

@keyframes placeholderMoveImg {
  0% {
    left: -80%;
  }
  100% {
    left: 100%;
  }
}

@keyframes placeholderMoveRight {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes placeholderMoveRight2 {
  0% {
    left: -120%;
  }
  100% {
    left: 100%;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}