@use '../abstracts/variables' as *;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;

  &--2 {
    display: block;
    overflow-y: auto;
  }

  @media (max-height: 700px) {
    display: block;
    padding: 50px 15px;
    overflow: auto;
  }
  @media (max-width: $break-sm-max) {
    padding: 15px;
  }

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $modal-bg;
    opacity: 0.75;
  }

  &__body {
    max-width: 918px;
    width: 100%;
    position: relative;
    z-index: 1;

    @media (max-height: 700px) {
      margin: 0 auto;
    }

    &--small {
      max-width: 385px;
    }

    .dashboard-box {
      margin-bottom: 0;
    }
  }
}