@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.solution {
  margin-bottom: 40px;
  position: relative;

  &::after {
    position: absolute;
    top: -12px;
    left: -12px;
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    content: '';
    background-color: $primary-light-color;
    box-shadow: 1px 3px 7px 0 rgba($shadow-color-2, 0.5);
    border-radius: 4px;
    opacity: 0;
    transition: opacity .3s ease;
  }

  &:hover {

    &::after {
      opacity: 1;
    }
  }

  &__img {
    margin-bottom: 22px;
    position: relative;
    border: 1px solid #8080804d;
    min-width: 270px;
    min-height: 200px;
    overflow: hidden;
    z-index: 1;

    cursor: pointer;

    img {
      @include centerElement;
      display: block;
      width: 100%;
      object-fit: cover;
      &.default{
        width: initial;
      }
    }

    .loader{
      @include centerElement
    }
    
  }

  &__info {
    position: relative;
    z-index: 1;
  }

  &__heading {
    font-family: $ff-primary-demi;
    margin-bottom: 11px;
  }

  &__desc {
    font-size: 0.8125rem;
    line-height: 1.461;
    margin-bottom: 10px;
  }

  &__choice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    > p {
      font-size: 0.875rem;
      color: $secondary-text-color;
      max-width: 98px;
    }

    .radio__choices {
      > div:first-child {
        margin-right: 25px;
      }
    }
  }

  &--list {
    display: flex;
    
    .solution__img {
      width: 270px;
      margin-right: 30px;
    }

    .solution__info {
      width: 270px;
    }

    &-centered {
      align-items: center;

      .solution__info {
        margin-top: -11px;

        @media (max-width: $break-md-max) {
          margin-top: 0;
        }
      }
    }

    @media (max-width: $break-md-max) {
      display: block;

      .solution__img {
        max-width: 270px;
        width: 100%;
        margin-right: 0;
      }
  
      .solution__info {
        max-width: 270px;
        width: 100%;
      }
    }
  }
}
