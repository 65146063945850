@use '../abstracts/variables' as *;

.regions {
  display: flex;
  flex-wrap: wrap;

  section {
    width: calc(50% - 40px);
    margin-bottom: 20px;

    &:nth-child(2n+1) {
      margin-right: 40px;
    }

    @media (max-width: $break-lg-max) {
      width: 100%;

      &:nth-child(2n+1) {
        margin-right: 0;
      }
    }

    h3 {
      font-family: $ff-primary-demi;
      font-size: 1.5rem;
      line-height: 1.33;
      color: $primary-color;
      margin-bottom: 10px;
    }

    .items {
      column-gap: 20px;
      column-count: 3;
      display: block;

      @media (max-width: $break-sm-max) {
        column-count: 2;
      }

      @media (max-width: $break-xxs-max) {
        column-count: 1;
      }

      li {
        font-size: 1.125rem;
        line-height: 1.2;
        display: inline-block;
        vertical-align: top;
        width: 100%;
        
        a {
          color: $primary-color;
          display: inline-block;
          padding: 7px 0;
          text-decoration: none;
          transition: color .3s ease;

          &:hover, &:active {
            color: #202967;
            text-decoration: underline;
          }

          span {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}