@use '../abstracts/variables' as *;

.solution-detail {

  &__top {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    h2 {
      margin-bottom: 10px;
    }

    .btn {
      margin-bottom: 10px;
    }
  }

  &__main {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $break-md-max) {
      display: block;
    }

    .solution-images {
      max-width: 500px;
      margin-right: 57px;

      @media (max-width: $break-xl-max) {
        margin-right: 20px;
        max-width: 400px;
      }
      @media (max-width: $break-lg-max) {
        margin-bottom: 20px;
        margin-right: 0;
        max-width: 500px;
      }
    }
    .solution-info {
      flex: 1;
    }
  }
  
  &__subtitle {
    font-size: 11px;
    color: gray;
  }
}