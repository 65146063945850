@use '../abstracts/variables' as *;

.text-link {
  font-family: $ff-secondary;
  color: $primary-text-color;
  transition: color .3s ease;

  &:hover {
    color: $primary-color;
  }
}