@use '../abstracts/variables' as *;

.dashboard-mobile-menu {
  display: none;
  align-items: center;
  justify-content: center;
  background-color: $secondary-color;
  height: 60px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;

  @media (max-width: $break-lg-max) {
    display: flex;
  }

  &__link {
    display: block;
    color: $primary-light-color;
    text-decoration: none;
    text-align: center;
    margin-right: 44px;

    @media (max-width: $break-xxs-max) {
      margin-right: 20px;
    }

    &:last-child {
      margin-right: 0;
    }

    &_icon {
      margin-bottom: 2px;
      height: 24px;
      display: flex;
      align-items: center;

      svg {
        display: block;
        font-size: 1.5rem;
        margin: 0 auto;
      }

      &--small {

        svg {
          font-size: 1.125rem;
        }
      }
    }

    &_text {
      display: block;
      font-family: $ff-primary-demi;
      font-size: 0.6875rem;
      line-height: 1.82;
    }
  }
}