@use '../abstracts/variables' as *;

.dashboard {
  min-height: 100%;
  background-color: rgba($secondary-bg-color, 0.3);
  padding-left: 216px;
  transition: padding .3s ease;
  display: flex;

  @media (min-width: $break-lg-min) {
    &--menu-shrinked {
      padding-left: 51px;
  
      .selected-projects {
        left: 51px;
        width: calc(100% - 51px);
      }
    }
  }

  @media (max-width: $break-lg-max) {
    padding-left: 0;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    flex: 1;

    &_top {
      background-color: $primary-light-color;
      padding: 20px 78px 20px 55px;
      position: relative;

      @media (max-width: $break-md-max) {
        padding: 20px 15px;
      }

      &--inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
      }

      h1 {
        font-size: 1.5rem;

        @media (max-width: $break-xs-max) {
          font-size: 1.25rem;
        }
      }

      &-right {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn {
          min-width: 120px;

          i {
            font-size: 1.125rem;
          }
        }

        .user {
          display: flex;
          align-items: center;
          margin-left: 22px;
          color: $primary-border-color;
          cursor: pointer;
          position: relative;
          transition: color .3s ease;

          &:hover,
          &.active {
            color: $primary-color;
          }

          svg {
            font-size: 1.25rem;
          }

          span {
            font-size: 0.875rem;
            font-family: $ff-primary-medium;
            margin: 0 10px;
          }

          &__dropdown {
            // position: absolute;
            // top: calc(100% + 15px);
            // right: 0;
            // width: 120px;
            // background-color: $primary-light-color;
            // border-radius: 4px;
            // box-shadow: 0 2px 6px 0 rgba($shadow-color, 0.3);
            // z-index: 10;
            // padding: 5px 0;
            position: absolute;
            top: calc(100% + 10px);
            right: 0;
            min-width: 100%;
            max-width: 185px;
            border: 2px solid $quinary-border-color;
            background-color: $primary-light-color;
            border-radius: 4px;
            z-index: 10;
            padding: 10px 11px 10px 15px;

            @media (max-width: $break-sm-max) {
              top: calc(100% + 15px);
            }

            &::after, &::before {
              bottom: 100%;
              left: 50%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;

              @media (max-width: $break-sm-max) {
                left: auto;
                right: 10px;
              }
            }
            
            &::after {
              border-color: rgba($primary-light-color, 0);
              border-bottom-color: $primary-light-color;
              border-width: 8px;
              margin-left: -8px;

              @media (max-width: $break-sm-max) {
                right: 12px;
              }
            }
            &::before {
              border-color: rgba($quinary-border-color, 0);
              border-bottom-color: $quinary-border-color;
              border-width: 11px;
              margin-left: -11px;
            }

            li {
              font-size: 0.875rem;
              font-family: $ff-primary-medium;
              padding: 0;
              margin-bottom: 2px;

              &:last-child {
                margin-bottom: 0;
              }
              
              a {
                color: $primary-text-color;
                text-decoration: none;
                transition: color .3s ease;
                display: flex;
                align-items: center;
                padding: 5px 0;
                white-space: nowrap;

                i,
                svg {
                  font-size: 1.25rem;
                  margin-right: 5px;
                }

                i::before {
                  transition: color .3s ease;
                }

                &:hover {
                  color: $primary-color;

                  i::before {
                    color: $primary-color;
                  }
                }
              }
            }
          }
        }

        @media (max-width: $break-sm-max) {
          .btn {
            min-width: auto;

            &__icon {
              margin-right: 0;
            }

            &__text {
              display: none;
            }
          }

          .user {
            margin-left: 15px;

            span {
              display: none;
            }
          }
        }
      }
    }

    &_main {
      padding: 52px 78px 52px 55px;
      max-width: 100%;

      @media (max-width: $break-md-max) {
        padding: 50px 15px;
      }
    }

    .footer {
      margin-top: auto;

      @media (max-width: $break-lg-max) {
        padding-bottom: 82px;
      }
    }
  }
}