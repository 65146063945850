@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;
@use '../abstracts/animations' as *;

.side-menu {
  width: 216px;
  background-color: $secondary-color;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: width .3s ease;

  &__top {
    padding: 24px 36px 0 24px;
    margin-bottom: 36px;
    height: 59px;
    display: flex;
    align-items: center;
    transition: padding .3s ease;

    &_toggle {
      cursor: pointer;
      width: 24px;
      padding: 0 3px;

      svg {
        display: block;
        color: $primary-light-color;
        font-size: 1.5rem;
      }

      div {
        height: 2px;
        width: 18px;
        background-color: $primary-light-color;
        overflow: hidden;
        position: relative;
        margin-bottom: 3px;

        span {
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: $primary-border-color;
          display: none;
          animation: placeholderMoveRight 1s ease-in-out infinite;
        }

        &:nth-child(2) {
          width: 12px;
          
          span {
            left: -120%;
            animation: placeholderMoveRight2 1s ease-in-out infinite;
          }
        }

        &:nth-child(3) {
          width: 6px;
          margin-bottom: 0;
        }
      }

      &:hover {

        div span {
          display: block;
        }
      }
    }

    img {
      display: block;
      max-width: 110px;
      max-height: 35px;
      margin-left: 15px;
    }
  }

  &__main {
    padding: 0 36px 0 24px;
    margin-bottom: 36px;
    transition: padding .3s ease;

    &_section {
      margin-bottom: 37px;

      &:last-child {
        margin-bottom: 0;
      }

      h6 {
        font-family: $ff-primary-demi;
        font-size: 0.75rem;
        color: $quaternary-text-color;
        text-transform: uppercase;
        margin-bottom: 26px;
      }

      ul {

        li {
          margin-bottom: 25px;
          position: relative;
          height: 19px;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            display: flex;
            align-items: center;
            color: $primary-light-color;
            font-family: $ff-primary-demi;
            text-decoration: none;
            font-size: 0.875rem;
            opacity: 0.5;
            transition: opacity .3s ease;

            &.icon-smaller {

              .icon {

                svg {
                  font-size: 1.125rem;
                }
              }
            }

            &:hover,
            &.active {
              opacity: 1;
            }

            .icon {
              min-width: 33px;
              padding-right: 3px;

              svg {
                display: block;
                font-size: 1.5rem;
              }

              img {
                display: block;
                width: 17px;
                transition: width .3s ease;
              }
            }

            .text {
              min-width: 90px;
            }

            .number {
              margin-left: auto;
              width: 17px;
              min-width: 17px;
              height: 17px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $secondary-color;
              background-color: $quaternary-bg-color;
              border-radius: 50%;
              font-size: 0.625rem;
              font-family: $ff-primary-bold;
            }
          }
        }
      }
    }
  }

  &__footer {
    margin-top: auto;
    padding: 16px 36px 16px 24px;
    border-top: 1px solid $tertiary-border-color;
    color: $primary-light-color;

    &_title {
      font-family: $ff-primary-demi;
      font-size: 0.875rem;
      margin-bottom: 1px;
      min-width: 120px;
    }
    
    &_version {
      font-family: $ff-primary-medium;
      font-size: 0.75rem;
      min-width: 120px;
    }
  }

  &.shrinked {
    width: 51px;

    .side-menu {

      &__top {
        padding: 24px 13px 0;
        margin-bottom: 78px;
        
        img {
          display: none;
        }
      }

      &__main {
        padding: 0 13px;

        &_section {
          margin-bottom: 80px;
          
          h6 {
            display: none;
          }

          ul {

            li {

              a {

                &:hover {

                  .text {
                    display: flex;
                  }
                }

                .text,
                .number {
                  display: none;
                }

                .text {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: calc(100% + 30px);
                  background-color: $primary-dark-color;
                  width: 90px;
                  height: 24px;
                  line-height: 24px;
                  padding: 0 10px;
                  display: none;
                  font-size: 0.75rem;

                  &::after {
                    top: 50%;
                    right: 100%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba($primary-dark-color, 0);
                    border-right-color: $primary-dark-color;
                    border-width: 6px;
                    margin-top: -6px;
                  }
                }

                .icon {

                  img {
                    width: 20px;
                  }
                }
              }
            }
          }
        }
      }

      &__footer {
        display: none;
      }
    }
  }

  @media (max-width: $break-lg-max) {
    display: none;
  }
}