@use '../abstracts/variables' as *;

.dashboard-box {
  background-color: $primary-light-color;
  box-shadow: 0 2px 4px 0 rgba(6, 62, 87, 0.36);
  border-radius: 5px;
  margin-bottom: 40px;

  &__head {
    padding: 32px 56px 29px;
    border-bottom: 2px solid rgba(11, 161, 226, 0.3);
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width: $break-sm-max) {
      padding-left: 20px;
      padding-right: 20px;
    }

    &_logo {
      position: absolute;
      top: 50%;
      right: 30px;
      width: 50px;
      height: 50px;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary-border-color;

      svg,
      img {
        display: block;
        max-width: 50px;
        max-height: 50px;
      }
    }

    .btn--mini {
      margin-left: 15px;
    }
  }

  &__body {
    padding: 40px 56px;
    border-radius: 0 0 5px 5px;

    @media (max-width: $break-sm-max) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &--small {

    .dashboard-box__head {
      padding: 25px 33px 17px;

      h3 {
        font-size: 1rem;
      }
    }
    .dashboard-box__body {
      padding: 37px 33px 24px;
    }

    @media (max-width: $break-sm-max) {

      .dashboard-box__head,
      .dashboard-box__body {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  &--primary {

    .dashboard-box__head {
      border-bottom: 2px solid rgba($primary-color, 0.3);
    }

    .dashboard-box__body {
      background-color: $quaternary-bg-color;
    }
  }
}