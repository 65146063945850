@use '../abstracts/variables' as *;
@use '../abstracts/mixins.scss' as *;

.form-input {
  margin-bottom: 15px;

  &--sm {
    width: 130px !important;
  }
  
  &__inner {
    position: relative;
  }

  &.error {
    .form-input__field {
      border-color: $error-border-color;
    }
  }

  &.with-icon {

    .form-input__field {
      padding-left: 42px;
    }
  }

  &__icon {
    color: $input-icon-color;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      display: block;
      font-size: 1.25rem;
    }
  }

  &__label {
    display: block;
    margin-bottom: 5px;
  }

  &__field {
    @include formField;
  }

  &__error {
    @include formError;
  }

  &--dashboard {
    margin-bottom: 29px;

    .form-input__label {
      @include formLabelDashboard;
    }

    .form-input__field {
      @include formFieldDashboard;
    }
  }

  &--dashboard-light {
    margin-bottom: 29px;

    .form-input__label {
      @include formLabelDashboard;
    }

    .form-input__field {
      @include formFieldDashboard;
      background-color: $primary-light-color;
    }
  }
}