@use '../abstracts/variables' as *;

.country-select {

  &--2 {

    .country-select__title {
      font-family: $ff-primary-demi;
      font-size: 2.25rem;
      color: $primary-color;
      margin-bottom: 20px;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-size: 0.875rem;
  }

  &__heading {
    font-family: $ff-primary-medium;
  }

  &__countries {
    overflow: hidden;
    
    &_inner {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% + 36px);

      .country {
        width: 190px;
        margin-right: 36px;
      }

      @media (max-width: $break-sm-max) {
        width: 100%;

        .country {
          width: 150px;
          margin-right: 0;

          .country-inner {
            width: 100%;
          }
        }
      }
    }
  }

}